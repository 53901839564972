.progress-text {
    text-align: left;
    align-self: center;
    font: normal normal 500 15px/32px Lato;
    letter-spacing: 0px;
    opacity: 1;
}

.progress-card{
    /*background-color: white !important;*/
    align-items: center;
    /*background: white;*/
}

 @keyframes download_rotate {
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
}

@-webkit-keyframes download_rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to { 
        -webkit-transform: rotate(360deg);
    }
}

.cirlce-load {
    width: 15px;
    height: 15px;
    border: 2px solid #131814;
    border-radius: 50%;
    /*border-right-color: transparent;*/
    border-bottom-color: transparent;
     -webkit-transition: all 0.5s ease-in;
    -webkit-animation-name:             download_rotate; 
    -webkit-animation-duration:         1.0s; 
    -webkit-animation-iteration-count:  infinite;
    -webkit-animation-timing-function: linear;
        
         transition: all 0.5s ease-in;
    animation-name:             download_rotate; 
    animation-duration:         1.8s; 
    animation-iteration-count:  infinite;
    animation-timing-function: linear; 
}

.progressbar-style{
    border-radius: 4px 4px 4px 4px;
    border: 2px solid rgba(234, 227, 227, 0.13);
    background: white;
    box-shadow: rgb(196 191 191) 2px 2px 12px 3px;
}