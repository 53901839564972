body {
  background: #F8F8F8 0% 0% no-repeat padding-box !important;
  opacity: 1 !important;
}

a{
  color: cadetblue;
}
.navbar {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #8E8C8C0A;
  opacity: 1;
}

.ml-auto{
  height: 30px;
}

.navbar-nav,
.nav-link {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.nav-item {
  margin-right: 10px;
  margin-left: 10px;
}

.nav-item-breaker {
  border: 1px solid #0D131A;
  opacity: 0.15;
}

#brand-icon {
  width: 157px;
  height: 39px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
}

#notification-icon {
  width: 18px;
  height: 23px;
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
}

.profile {
  background: #dfe7e8 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

#profile-icon {
  background: transparent 0% 0% no-repeat padding-box;
  opacity: 1;
  vertical-align: middle;
  width: 15px;
  height: 15px;
}

#user-name-nav {
  text-align: left;
  font: normal normal normal 16px/17px Lato, sans-serif ! important;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;
}

#profileview {
  margin-right: 5px;
}

#main-menu {
  border-top: 5px solid #f8f8f8;
  padding-top: 15px;
}

.main-menu-sub-group {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  margin: 15px;
}

.circle {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  opacity: 1;
}

.circle-title {
  text-align: left;
  font: normal normal normal 18px/22px Lato, sans-serif;
  letter-spacing: 0px;
}

.menu-active {
  color: #182027;
  opacity: 1;
}

#main-content {
  padding: 30px;
}

.create-rectangle {
  width:120px;
    height:100px;
    background:#fff;
    border-radius: 8px 8px 4px 4px;
    justify-content: center;
  align-items: center;
}

.search-icon {
  background-color: white; 
  border: 1px solid lightgray;
}

#refresh {
  margin-right: 20px;
}

#table-first-row  {
  height: 6px;
  border: 1px 0px 1px 0px;
}

#table-first-row td {
  background-color: #f8f8f8 !important;
  border:1px 0px 1px 0px;

}

table {
  text-align: center;
}

#tab td {
  background-color: #ffffff;
}

#batch-table {
  background-color: #fff;
}

.filter-font {
  background-color: white; 
  border: 1px solid lightgray; 
  color: #8080807d;
}

.white-bg {
  background-color: white;
}

a:hover {
    color: #0056b3 !important;
    text-decoration: underline ;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff !important;
    background-color: #007bff;
    border-color: #007bff;
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
}

.dot-elastic {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotElastic 1s infinite linear;
}

.dot-elastic::before, .dot-elastic::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-elastic::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotElasticBefore 1s infinite linear;
}

.dot-elastic::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dotElasticAfter 1s infinite linear;
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}

.inline-loader{
  display: flex;
  justify-content: center;
  position: absolute;
  padding: 2rem 0;
  overflow: hidden;
  z-index: 4;
  width: 100%;
  height: 100%;
  background: white;
  margin: 0;
}

.card-loader{
  display: flex;
  justify-content: center;
  position: absolute;
  padding: 2rem 0;
  overflow: hidden;
  z-index: 4;
  width: 100%;
  height: 100%;
  background: white;
  margin: 0;
}


a.logout-btn{
  cursor: pointer;
}
.relative{
  position: relative;
}

/*Added for fixed side nav*/
.sidenav {
  height: 100%; /* Full-height: remove this if you want "auto" height */
  width: 90px; /* Set the width of the sidebar */
  position: fixed; /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: white; /* Black */
  overflow-x: hidden; /* Disable horizontal scroll */
  margin-top: 60px;
}


.main {
  /*margin-left: 90px;*/ /* Same as the width of the sidenav */
  background-color: #f8f8f8;
  margin-top: 60px;
}
.active-navbar{
  color: black !important;
}

.btn-dark:hover {
  color: #fff !important;
  background-color: #23272b;
  border-color: #1d2124;
}

.stick-bottom {
  position: sticky;
  bottom: 0;
}

.show-progress {
    display: block !important;
}

.hide-progress {
    display: none !important;
}

.left-margin-menu {
    margin-left: 90px
}

.footer-padding {
    padding-left: 30px;
    padding-right: 30px;
}

#footer_content {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 50;
}

#minimize_or_maximize_progress {
    margin-top: 4px;
    margin-bottom: 8px;
    float:right;
    cursor:pointer;
}