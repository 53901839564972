.file-upload-wrapper {
	min-width: 400px;
	min-height: 200px;
	background-color: #f8f8f8;
}

#customFileLabel {
	/*border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;*/
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #CCD1E1;
    border-radius: 5px;
    opacity: 1;
}

#customFile {
	display: none;
}

#upload-btn {
	background: #182027 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
}

#add-new-batch-title {
	text-align: left;
	font: normal normal bold 21px/25px Lato;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
}


.files{ 
    position:relative;
    /*border: 3px dashed #0142fe;*/
    text-align: center;
    margin: 0;
    width: 100% !important;
    height:250px;
    overflow:hidden;
}

.files input {
    width: 100% !important;
    height:200px;
    border:none;
    padding:0;
    background:transparent;
    text-align:center;
}

.files{ position:relative}

.files:after {
    pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 70px;
    right: 0;
    height: 70px;
    content: "";
    background-image: url(../../images/cloud-computing.svg);
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}

.files:before {
  content:attr(data-title);
  position:absolute;
  bottom:55px;;
  left:0;
  right:0;
  display:block;
  /*text-align:center;
  color:#0142fe;
  font-weight:bold;*/
  text-align: center;
  font: normal normal normal 16px/19px Lato;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;
}

.xlsx-file{
    margin-left: 42px;
}
.zip-file{
    margin-left: 50px;
}
.zip-title{
    padding-top: 24px;
}





