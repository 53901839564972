.invalid-role {
    border: 1px solid red !important;
}


/* Styling smaller Checkbox Starts */
.checkbox-label {
    display: block;
    position: relative;
    margin: auto;
    cursor: pointer;
    font-size: 22px;
    line-height: 16px;
    height: 16px;
    width: 16px;
    clear: both;
}

.checkbox-label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-label .checkbox-custom {
    position: absolute;
    top: 4px;
    left: 0px;
    height: 16px;
    width: 16px;
    background-color: white;
    border-radius: 2px;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    border: 1px solid #CCD1E1;
}


.checkbox-label input:checked ~ .checkbox-custom {
    background-color: #dfe7e8;
    border-radius: 2px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity:1;
    border: none;
}


.checkbox-label .checkbox-custom::after {
    position: absolute;
    content: "";
    left: 8px;
    top: 8px;
    height: 0px;
    width: 0px;
    border-radius: 2px;
    border: solid #009BFF;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity:1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}


.checkbox-label input:checked ~ .checkbox-custom::after {
  -webkit-transform: rotate(45deg) scale(1);
  -ms-transform: rotate(45deg) scale(1);
  transform: rotate(45deg) scale(1);
  opacity:1;
  left: 5px;
  top: 3px;
  width: 6px;
  height: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}


.checkbox-label input:disabled ~ .checkbox-custom {
  background-color: #f8f8f8;
  cursor: not-allowed;
}

.checkbox-label input{
    cursor: default;
}

.default-pointer{
    cursor: default !important;
}

#role-save-btn {
    background: #182027 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
}

.margin-negative {
    margin-right: -15px;
    margin-left: -15px;
}


#role-name{
    font-weight: bold !important;
}