.img-parent {
	width: 100%;
	height: 213px;
	padding: 20px;
}

.image-inside-div{
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center center;

}

.card {
    height: 100%;
 }

 .image-description-divider {
	border: 1px solid #dbdcdd; opacity:0.4;
}

.design-description-div {
	
}

.gd-number {
	font: normal normal 500 12px/15px lato, sans-serif;
	letter-spacing: 0px;
	color: #8E9294;
	opacity: 1;
}

.variant-number {
	font: normal normal 500 12px/15px lato, sans-serif;
	letter-spacing: 0px;
	color: #8E9294;
	opacity: 1;
}

.design-description-each-row {
	padding-bottom: 5px;
}

.product-name {
	text-align: left;
	font: normal normal 500 15px/18px Lato, sans-serif;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	margin-bottom: 5px;
}

.vendor-design-number {
	text-align: left;
	font: normal normal 500 12px/15px Lato;
	letter-spacing: 0px;
	color: #8E9294;
	opacity: 1;
}

/*overriding the nav-bar css*/

#home-title {
  color: #6D767F;
  opacity: 1;
}

#batches-title {
	color: #6D767F;
	opacity: 1;
}

.circle-title {
  text-align: left;
  font: normal normal normal 18px/22px Lato, sans-serif;
  letter-spacing: 0px;
  color: #6D767F;
  opacity: 1;
}

.dot {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
  background: #0D131A 0% 0% no-repeat padding-box;
  opacity: 1;
}

.repo-index-status-selected {
	border: 1px solid gray;
	background: #e8e7e7 !important;
}

.create-rectangle {
	width:120px;
    height:100px;
    background:#fff;
    border-radius: 8px 8px 4px 4px;
    justify-content: center;
	align-items: center;
}

.repo-card a{
	text-decoration: none;
}

.repo-index-design-status-icon{
	position: absolute; 
 	top: 8px;
 	right: 8px;
}

.repo-index-design-img-parent{
	width: 100%;
	height: 220px;
	padding: 20px 20px 10px 20px;
}

.repo-index-design-inside-div{
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center center;
}

.repo-index-footer-vdi-number{
	text-align: left;
	font: normal normal 500 12px/15px Lato, sans-serif;
	letter-spacing: 0px;
	color: #8E9294;
	opacity: 1;
}

.repo-index-footeer-design-name{
	text-align: left;
	font: normal normal 500 15px/18px Lato, sans-serif;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
}

.repo-index-footer-vendor-design-number{
	text-align: left;
	font: normal normal 500 12px/15px Lato, sans-serif;
	letter-spacing: 0px;
	color: #8E9294;
	opacity: 1;
}

.repo-create-rectangle {
	/*width:120px;*/
	padding-left: 20px;
	padding-right: 20px;
	min-width: 120px;
	height:100px;
	/*background:#fff;*/
	justify-content: center;
	align-items: center;
}

.repo-status-title{
	text-align: center;
	font: normal normal normal 16px/19px Lato, sans-serif;
	letter-spacing: 0px;
	color: #6D767F;
	opacity: 1;
}

.repo-status-count{
	text-align: center;
	font: normal normal bold 24px/29px Lato;
	letter-spacing: 0px;
	color: #0D131A;
	opacity: 1;
}

.repo-rectangle-status-border{
	border-radius: 4px 4px 4px 4px;
}

.repo-rectangle-status-color{
	background:#fff;
}

.repo-studio-card-padding{
	padding: 20px;
}

.repo-index-design-new-images-count{
		position: absolute; 
	 	top: 8px;
	 	right: 8px;
}