.circle-with-text {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    font-size: 10px;
    color: #6D767F;
    text-align: center;
    background: #dfe7e8;
    text-transform: uppercase;
}

#users-table {
    background-color: white;
    margin: 0px;
}

#user-name-input-group {
    border: 1px solid #CCD1E1;
    border-radius: 5px;
    opacity: 1;
    align-items: center;
}

#user-name-input {
    border: none;
}

#user-model-content {
    max-width: 100%;
    min-height: 520px;
}

#user-model-dialog {
    max-width: 750px;
}

.option_width {
    width: 48%;
}

.dashed-row-bottom {
    border-bottom: 1px dashed #dee2e6;
}

.dashed-row-top {
    border-top: 1px dashed #dee2e6;
}

#add-user-text {
    text-align: left;
    font: normal normal normal 14px/17px Lato, sans-serif;
    letter-spacing: 0px;
    color: #182027;
    opacity: 1;
}

#user-name-text {
    text-align: left;
    font: normal normal normal 14px/17px Lato, sans-serif;
    letter-spacing: 0px;
    color: #182027;
    opacity: 1;
}

#sourcing-location-text {
    text-align: left;
    font: normal normal normal 14px/17px Lato, sans-serif;
    letter-spacing: 0px;
    color: #182027;
    opacity: 1;
}

#product-category-text {
    text-align: left;
    font: normal normal normal 14px/17px Lato, sans-serif;
    letter-spacing: 0px;
    color: #182027;
    opacity: 1;
}

#domain-text {
    text-align: left;
    font: normal normal normal 14px/17px Lato, sans-serif;
    letter-spacing: 0px;
    color: #182027;
    opacity: 1;
}

#role-text {
    text-align: left;
    font: normal normal normal 14px/17px Lato, sans-serif;
    letter-spacing: 0px;
    color: #182027;
    opacity: 1;
}

#note-text {
    text-align: left;
    font: italic normal normal 14px/17px Lato;
    letter-spacing: 0px;
    color: #182027;
    opacity: 1;
}

.custom-url a{
    color: #212529 !important;
}

.add-user-autocomplete {
  /*the container must be positioned relative:*/
  position: relative;
  display: inline-block;
}

.add-user-autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.add-user-autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}
.add-user-autocomplete-items div:hover {
  /*when hovering an item:*/
  background-color: #e9e9e9;
}
.add-user-autocomplete-items-active {
  /*when navigating through the items using the arrow keys:*/
  background-color: DodgerBlue !important;
  color: #ffffff;
}

#job-search-input-group{
    border: 1px solid #CCD1E1;
    opacity: 1;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 5px 5px;
}