body {
	background: #F8F8F8 0% 0% no-repeat padding-box;
	opacity: 1;
}

.navbar-nav,
.nav-link {
  padding-top: 0;
  padding-bottom: 0;  
}

.nav-item {
	margin-right: 10px;
	margin-left: 10px;
}

.nav-item-breaker {
	border: 1px solid #0D131A;
	opacity: 0.15;
}

#brand-icon {
	width: 157px;
	height: 39px;
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

.navbar {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #8E8C8C0A;
	opacity: 1;
}

#notification-icon {
	width: 18px;
	height: 23px;
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

#profile-icon {
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
	vertical-align: middle;
	width: 15px;
	height: 15px;
}

.profile {
	background: #dfe7e8 0% 0% no-repeat padding-box;
	opacity: 1;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
}

#product-name {
	text-align: left;
	font: normal normal 500 24px/29px Lato, sans-serif;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	margin:0;
}

#user-name {
	text-align: left;
	font: normal normal normal 14px/17px Lato, sans-serif;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
}

#arrow-icon {
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

#profileview {
	margin-right: 5px;
}

.white-bg {
	background-color: white;
}

#main-menu {
	border-top: 5px solid #f8f8f8;
	padding-top: 15px;
}

.main-menu-sub-group {
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	margin: 15px;
}

#main-content {
	padding: 30px;
}

#current-url-total-path {
	margin: 0;
}

.current-url {
	text-align: left;
	font: normal normal 500 15px/18px Lato, sans-serif;
	letter-spacing: 0px;
	opacity: 1;
	color: #222222;
}

.p3-product-name {
	text-align: left;
	font: normal normal 500 15px/18px Lato, sans-serif;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	margin-bottom: 5px;
}

.p3-vendor-design-number {
	text-align: left;
	font: normal normal 500 12px/15px Lato;
	letter-spacing: 0px;
	color: #8E9294;
	opacity: 1;
}

#product-date {
	text-align: left;
	font: normal normal 500 14px/18px Lato, sans-serif;
	letter-spacing: 0px;
	color: #636A81;
	opacity: 1;
	margin: 0;
	padding: 0px;
}

.sub-content{
	margin-left: 20px;
}

#product-info-card {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
	padding: 5px;
	margin-right: 15px;
}

#product-info-navigation {
	padding-left: 10px;
	padding-right: 10px;
}

.navigation-text-font {
	font: normal normal 500 14px/17px lato, sans-serif;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
}

#vdi {
	padding: 20px 20px 5px 20px;
}

.p3-vdi-number {
	font: normal normal 500 12px/15px lato, sans-serif;
	letter-spacing: 0px;
	color: #8E9294;
	opacity: 1;
}

#ios-more{
	width: 30px
}

.main-design-image {
	max-width: 100%;
	padding: 0 20px 9px 20px;
}

.main-design-image-group {
	width: 100%;
	display: table;
	padding: 0 20px 5px 20px;
}

.main-design-image-group div + div {
	margin-left: 13px;
}

.cell-image {
	display: table-cell;
	margin-top: 8px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
}

#tablist {
	padding:0px 15px 15px 15px;
}

.main-design-child-image {
	max-width: 100%;
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

.tab-content-attribute {
	width: 500px;
}

.tab-content { 
	 margin-top: 15px;
}

.attribute-background {
	background-color:#FFFFFF;
	padding:10px;
	padding-bottom: 25%;
}

.tablist-table {
	margin-bottom: 20%;
}

.create-rectangle {
	width:120px;
    height:100px;
    background:#fff;
    border-radius: 8px 8px 4px 4px;
    justify-content: center;
	align-items: center;
}


.circle {
	height: 25px;
	width: 25px;
	border-radius: 50%;
	display: inline-block;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid #707070;
	opacity: 1;
}

.circle-title {
	text-align: left;
	font: normal normal normal 18px/22px Lato, sans-serif;
	letter-spacing: 0px;
	color: #6D767F;
	opacity: 1;
}

.batch-card {
	height: 100%;
}

#batches-title {
	font: normal normal normal 18px/22px Lato, sans-serif;
	color: #182027;
    opacity: 1;
}

#gos-group {
	margin-top: 10px;
	margin-bottom: 10px;
    align-items: center;
}

#status-and-filter-row {
	margin: 10px 0px 10px 0px;
}

#status-column {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid #8F96AC;
	border-radius: 5px;opacity: 1;
	padding:10px 0px 10px 0px;
}

#filter-dropdown {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid #8F96AC;
	border-radius: 5px;opacity: 1;
	padding:10px
}


.card-row-color {
	background-color: #f8f8f8;
}

.card-group {
	padding-top: 15px;
}

.card-img-center {
	padding: 15px;
}

.status-icon-div {
	padding-right: 10px;
	padding-top: 0px;
}

.status-icon {
	border-radius:50%;
	background-color:white;
}

.image-description-divider {
	border: 1px solid #dbdcdd; opacity:0.1;
}

.batch-design-description-div {
	padding:20px;
}

.design-description-each-row {
	padding-bottom: 5px;
}

.info-number {
    display: block;
    font-weight: bolder;
    font-size: 17pt;
    text-align: center;
    /* color: #4d9de8; */
	color:black;
    word-break: break-all;
}

.info-text {
    font-size: 14px;
    display: block;
    text-align: center;
    text-transform: capitalize;
}
.create-rectangle {
	width: 135px !important;
	cursor: pointer;
}

#status-and-filter-row .active{
	border: 1px solid green;
}
#batch-status-approve{
	margin-right: 10px;
}
.batch-details-filter .attribute-filters{
	background: white;
	padding: 15px;
	margin-left: 0px !important;
	margin-top: 15px !important;
	display: block;
}
.batch-details-filter .weight-filters{
	background: white;
	padding-bottom: 15px;
	margin-left: 0px !important;
	margin-bottom: 27px !important;

}

.repo-page-filters .attribute-filters{
	background: white;
	padding: 15px;
	margin-left: 14px !important;
	margin-top: 15px !important;
	display: block;
}
.repo-page-filters .weight-filters{
	background: white;
	padding-bottom: 15px;
	margin-left: 14px !important;
	margin-bottom: 0px !important;

}

/*.batch-status-change-icon {
	border-radius:50%;
	background-color:white;
 	opacity: 1;
 	position: absolute; 
  	top: 12px;
  	right:12px;
 }

.batch-img-parent {
	width: 100%;
	height: 240px;
	padding: 20px;
}

.batch-image-inside-div{
	width: 100%;
	height: 100%;
	object-fit: cover;
	object-position: center center;*/


.border-custom{
    border: 1px solid red;    
}

.progress-text {
    text-align: left;
    align-self: center;
    font: normal normal 500 15px/32px Lato;
    letter-spacing: 0px;
    opacity: 1;
}


.progress-bar-custom-style {
      background-color: #f8f8f8 !important;
      color: black !important;
      background-image: linear-gradient(45deg,rgba(0, 0, 0,.15) 25%,transparent 25%,transparent 50%,rgba(0, 0, 0,.15) 50%,rgba(0, 0, 0,.15) 75%,transparent 75%,transparent) !important;
      background-size: 1rem 1rem !important;
}