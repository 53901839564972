#new-role-name{
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    min-width: 300px;
    max-width: 600px;
}
.aligin-middle{
    vertical-align: middle !important;
}

.permission-row {
    min-height: 70px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#update-role-save-btn {
    background: #182027 0% 0% no-repeat padding-box !important;
    border-radius: 4px;
    opacity: 1;
    color: #fff;
}

#delete-role-save-btn {
    background: #182027 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    color: #fff;
}