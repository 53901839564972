.user-profile-left {
    background-color: white;
    border-radius: 5px;
}
.user-profile-right {
    background-color: white;
    border-radius: 5px;
}

.user-profile-image{
    width: 100%;
    height: 100%;
    margin:auto;
    display:block;
}

.user-profile-image-div{
    height: 60px;
    min-width: 80px;
    width: 100%;
}

.user-profile{
}

.user-profile-titles {
    text-align: left;
    font: normal normal bold 14px/17px Lato;
    letter-spacing: 0px;
    color: #182027;
    opacity: 1;
}

.user-profile-details-text{
    text-align: left;
    font: normal normal normal 14px/17px Lato;
    letter-spacing: 0px;
    color: #182027;
    opacity: 1;
}

#users-details-table {
    background-color: white !important;
    margin: 0px;
}

.users-details-menu{
    min-width: 10px !important;
}

.users-details-menu-item{
    padding: 0px !important;
    margin: 4px;
}

.roles-td{
    width: 23% !important;
}

#users-details-table td{
    vertical-align: middle;
}

#product-type-text {
    text-align: left;
    font: normal normal normal 14px/17px Lato, sans-serif;
    letter-spacing: 0px;
    color: #182027;
    opacity: 1;
}

#user-time-zone{
    min-width:200px;
}

#country-select{
    min-width:200px;
}

.user_name{
    font-size: 18px;
}