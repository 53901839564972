#sign-in-title {
	text-align: left;
	font: normal normal bold 18px/30px Lato;
	letter-spacing: 0px;
	color: #182027;
	text-transform: uppercase;
	opacity: 1;
}

.no-border {
	border: none !important;
}

#sign-in-card {
	width: 450px;
	margin:0 auto;
	background-color: white;
	position: relative;
}

body{
	background-color: #f8f8f8;
}

#sign-in-btn {
	min-width: 25%;
}

.custom-border-bottom {
	border-bottom: 1px solid #CCD1E1;
}
.Login-main-container .inline-loader{
	width: 80% !important;
	height: 82% !important; 
}



/*Round checkbox*/
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 22px;
  left: 0;
  position: absolute;
  top: 0;
  width: 22px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 4px;
  left: 4px;
  opacity: 0;
  position: absolute;
  top: 7px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #ccd1e1;
  border-color: #ccd1e1;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

#email-id-label {
	text-align: left;
	font: normal normal normal 14px/17px Lato, sans-serif ;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
}

#pwd-label {
	text-align: left;
	font: normal normal normal 14px/17px Lato, sans-serif;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
}

#remember-label {
	text-align: left;
	font: normal normal normal 14px/17px Lato, sans-serif;
	letter-spacing: 0px;
	color: #6D767F;
	opacity: 1;
}

#forgot-pwd-span {
	text-align: left;
	font: normal normal normal 14px/17px Lato, sans-serif;
	letter-spacing: 0px;
	color: #6D767F;
	opacity: 1;
}

#sign-in-btn:hover {
    color: #fff !important;
}
.login-image{
	width: 50%;
	height: auto;
}

.o365icon{
    background: url("./o365.png") no-repeat scroll 0 0 transparent;
    background-size: 100%;
    width: 11%;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer;
    max-height: 100%;
    border-style: none;
    margin-right: 20px;
    padding-bottom: 50px;
}

.or-seperator {
    margin: 20px 0 0px;
    text-align: center;
    border-top: 1px solid #ccc;
}

.or-seperator i {
    padding: 0 10px;
    background: #ffffff;
    position: relative;
    top: -13px;
    z-index: 1;
}

.microsoft-btn {
	border: 1px solid #343a40 !important;
	border-radius: .25rem !important;
}