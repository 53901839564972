.attribute-dropdown {
    position: relative;
    display: inline-block;
    margin: 10px;
}

.attribute-btn {
    display: inline-block;
}

.dropbtn {
    padding: 0.5rem;
    color: #292728 !important;
    font-size: 10pt;
    text-transform: uppercase;
    vertical-align: middle;
    border: 1px solid #ddd;
}

a.dropbtn .arrow-down-icon {
    transition: all .4s ease;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
    z-index: 3;
    height: 200px;
    overflow-y: scroll;
}

.dropdown-content a {
    color: #000;
    padding: 0.4rem 0.8rem;
    text-decoration: none;
    display: block;
    font-size: 10pt;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #007bff;
    background-color: #fff;
    border: 1px solid #dee2e6;
}

.dropdown-content a:hover {background-color: #ddd;}

 div.attribute-btn:hover .dropdown-content {display: block;}
div.attribute-btn:hover .dropbtn .arrow-down-icon {
    transform: rotateZ(180deg);
}
/*div.attribute-btn:focus .dropdown-content {display: block;}*/
 a.dropbtn .arrow-down-icon{
    transition: all 0.4s ease;
}

 a.item-attr-link{
color: #292728;transition: all 0.4s ease;
font-size: 10pt;
border-color: #e1e1e1;
cursor: pointer;
text-transform: capitalize;
padding: 0.36rem;
text-decoration: none;
background-color: #fff;
border: 1px solid #ddd;
position: relative;
vertical-align: middle;
display: inline-block;
border-left:none;
/* padding-left: 10px; */

}
.attribute-btn{
display: inline-block;
}
a.item-attr-link.active{
/* color: white !important; */
background: linear-gradient(#e9e9e9,#e2e1e1);
border-color: #e9e9e9;
text-decoration:none;
cursor: pointer;
border-right: 1px solid white;
}
a.item-attr-link.clear-filter{
padding: 0.53rem;
text-decoration: none;
}
.weight-label{
    align-self: end;
}
.weight-input{
    text-align: center;
    margin-top: 10px;
}
a:hover {
    color: #292728 !important;
    text-decoration: underline;
}
.attribute-name{
    padding-right: 10px;
}
.minimum-weight,.maximum-weight{
    height: 33px !important;
    border-radius: 0 !important;
    width: 88px !important;
    text-align: right;
    font-size: 9pt !important;
}
.weight-label{
    text-align: left;
    padding-right: 0;
}
a.page-link.active{
    background:#e7e7e7;
}

.dropdown-content::-webkit-scrollbar {
    width: 3px !important;
   background: lightgray;
  }
  .dropdown-content::-webkit-scrollbar-thumb {
    background: #888;
  }