.repo-image-upload-wrapper {
	min-width: 400px;
	min-height: 200px;
	background-color: #f8f8f8;
}

#repo-upload-images-title{
	text-align: left;
	font: normal normal bold 21px/25px Lato;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
}