body {
	background: #F8F8F8 0% 0% no-repeat padding-box;
	opacity: 1;
}

.navbar-nav,
.nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

.nav-item {
	margin-right: 10px;
	margin-left: 10px;
}

.nav-item-breaker {
	border: 1px solid #0D131A;
	opacity: 0.15;
}

#brand-icon {
	width: 157px;
	height: 39px;
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

.navbar {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #8E8C8C0A;
	opacity: 1;
}

#notification-icon {
	width: 18px;
	height: 23px;
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

#profile-icon {
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
	vertical-align: middle;
	width: 15px;
	height: 15px;
}

.profile {
	background: #dfe7e8 0% 0% no-repeat padding-box;
	opacity: 1;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
}

#user-name {
	text-align: left;
	font: normal normal normal 14px/17px Lato, sans-serif;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
}

#arrow-icon {
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

#profileview {
	margin-right: 5px;
}

.white-bg {
	background-color: white;
}

#main-menu {
	border-top: 5px solid #f8f8f8;
	padding-top: 15px;
}

.main-menu-sub-group {
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	margin: 15px;
}

#main-content {
	padding: 30px;
}

#current-url-total-path {
	margin-bottom: 5px;
}

.current-url {
	text-align: left;
	font: normal normal 500 15px/18px Lato, sans-serif;
	letter-spacing: 0px;
	opacity: 1;
	color: #222222;
}

#product-name {
	text-align: left;
	font: normal normal 500 24px/29px Lato, sans-serif;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	margin-bottom: 5px;
}

.status-button {
	border: 1px solid #707070 !important;
	border-radius: 5px !important;
	opacity: 1 !important;
}

#product-date {
	text-align: left;
	font: normal normal 500 14px/18px Lato, sans-serif;
	letter-spacing: 0px;
	color: #636A81;
	opacity: 1;
	padding-bottom: 20px;
	margin-bottom: 5px;
}

.navigation-text-font {
	font: normal normal 500 14px/17px lato, sans-serif;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
}

.main-design-image-group {
	width: 100%;
	display: table;
	padding: 0 20px 5px 20px;
}

.main-design-image-group div + div {
	margin-left: 13px;
}

.cell-image {
	display: table-cell;
	margin-top: 8px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
}

#tablist {
	padding:0px 15px 15px 15px;
}

.main-design-child-image {
	max-width: 100%;
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

.similar-design-menu-group {
	padding-left: 15px;
	padding-right: 15px;
}




.similar-design-image {
	object-fit: cover;
	width: 248px;
	height: auto;
	/*width: 224px;
	height: 150px;*/
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

.description {
	font: normal normal normal 12px/15px Lato, sans-serif;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
	padding-top: 5px;
	padding-bottom: 0px;
	margin: 0px;
}

.tablist-table {
	margin-bottom: 20%;
}

.create-rectangle {
	width:120px;
    height:100px;
    background:#fff;
    border-radius: 4px 4px 4px 4px;
    justify-content: center;
	align-items: center;
	margin-left: 5px;
	margin-right: 5px;
}

/*aligned properly until above*/

.circle {
	height: 25px;
	width: 25px;
	border-radius: 50%;
	display: inline-block;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid #707070;
	opacity: 1;
}

.circle-title {
	text-align: left;
	font: normal normal normal 18px/22px Lato, sans-serif;
	letter-spacing: 0px;
	color: #6D767F;
	opacity: 1;
}

#home-title {
	color: #182027;
	opacity: 1;
}

.similar-row-image-group{
	width: 100%;
	display: table;
	padding-top: 2px;

}

#text-underline{
	text-decoration-line: underline;
}


u{
	text-decoration: none;
	border-bottom: 2px solid black;
}



#batch-table {
	background-color: #fff;
}





.nav li a{
	color: #6c757d;
	opacity: 0.5;
}



.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

.nav-tabs{
	border:0px !important;
}
.nav-tabs .nav-link.active {
    color: black !important;
    background-color: transparent !important;
    border: 0px !important;
    border-bottom: 3px solid gray !important;
    opacity: 1 !important;
}



/*updated here*/


.bgc{
	background: #F8F8F8 0% 0% no-repeat padding-box;
    opacity: 1;
 }


table {
	text-align: center;
}

.search-icon {
	background-color: white;
	border: 1px solid lightgray;

}

.filter-font {
	background-color: white;
	/* border:0px !important; */
	color: #8080807d;
	margin-right: 10px;
}

#refresh {
	margin-right: 20px;
}

#table-first-row  {
	height: 6px;
	border: 1px 0px 1px 0px;
}

#table-first-row td {
	background-color: #f8f8f8 !important;
	border:1px 0px 1px 0px;

}

#tab td {
	background-color: #ffffff;
}
body {
	background: #F8F8F8 0% 0% no-repeat padding-box;
	opacity: 1;
}

.navbar-nav,
.nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

.nav-item {
	margin-right: 10px;
	margin-left: 10px;
}

.nav-item-breaker {
	border: 1px solid #0D131A;
	opacity: 0.15;
}

#brand-icon {
	width: 157px;
	height: 39px;
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

.navbar {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #8E8C8C0A;
	opacity: 1;
}

#notification-icon {
	width: 18px;
	height: 23px;
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

#profile-icon {
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
	vertical-align: middle;
	width: 15px;
	height: 15px;
}

.profile {
	background: #dfe7e8 0% 0% no-repeat padding-box;
	opacity: 1;
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
}

#user-name {
	text-align: left;
	font: normal normal normal 14px/17px Lato, sans-serif;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
}

#arrow-icon {
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

#profileview {
	margin-right: 5px;
}

.white-bg {
	background-color: white;
}

#main-menu {
	border-top: 5px solid #f8f8f8;
	padding-top: 15px;
}

.main-menu-sub-group {
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	margin: 15px;
}

#main-content {
	padding: 30px;
}

#current-url-total-path {
	margin-bottom: 5px;
}

.current-url {
	text-align: left;
	font: normal normal 500 15px/18px Lato, sans-serif;
	letter-spacing: 0px;
	opacity: 1;
	color: #222222;
}

#product-name {
	text-align: left;
	font: normal normal 500 24px/29px Lato, sans-serif;
	letter-spacing: 0px;
	color: #222222;
	opacity: 1;
	margin-bottom: 5px;
}

.status-button {
	border: 1px solid #707070;
	border-radius: 5px;
	opacity: 1;
}

#product-date {
	text-align: left;
	font: normal normal 500 14px/18px Lato, sans-serif;
	letter-spacing: 0px;
	color: #636A81;
	opacity: 1;
	padding-bottom: 20px;
	margin-bottom: 5px;
}

.sub-content{
	margin-left: 20px;
}

#product-info-navigation {
	padding-left: 10px;
	padding-right: 10px;
}

#vdi {
	padding: 20px 20px 5px 20px;
}

#vdi-number {
	font: normal normal 500 15px/18px lato, sans-serif;
	letter-spacing: 0px;
	color: #636A81;
	opacity: 1;
}

#ios-more{
	width: 30px
}



.main-design-image-group {
	width: 100%;
	display: table;
	padding: 0 20px 5px 20px;
}


.cell-image {
	display: table-cell;
	margin-top: 8px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
}

#tablist {
	padding:0px 15px 15px 15px;
}

.main-design-child-image {
	max-width: 100%;
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

#similar-design-btn,#attribute,#edit-history {
	text-align: left;
	font: normal normal normal 18px/22px lato, sans-serif;
	letter-spacing: 0px;
}

#attribute {
	text-align: left;
	/*font: normal normal normal 18px/22px Lato, sans-serif;*/
	letter-spacing: 0px;
}

#edit-history {
	text-align: left;
	font: normal normal normal 18px/22px lato, sans-serif;
	letter-spacing: 0px;
}

.similar-design-image-group {
	margin-left: 0px !important;
}
.vari-attr-values{
	line-break: anywhere;
	text-align: left;
}

.similar-cell-image {
	padding: 13px;
	display: table-cell;
	margin: 4px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;

}

.tab-content-attribute {
	width: 500px;
}

.tab-content {
	 margin-top: 15px;
}

.attribute-background {
	background-color:#FFFFFF;
	padding:10px;
	padding-bottom: 25%;
}

.tablist-table {
	margin-bottom: 20%;
}

/*aligned properly until above*/

.circle {
	height: 25px;
	width: 25px;
	border-radius: 50%;
	display: inline-block;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid #707070;
	opacity: 1;
}

.circle-title {
	text-align: left;
	font: normal normal normal 18px/22px Lato, sans-serif;
	letter-spacing: 0px;
	color: #6D767F;
	opacity: 1;
}

#home-title {
	color: #182027;
	opacity: 1;
}

.similar-row-image-group{
	width: 100%;
	display: table;
	padding-top: 2px;

}

#text-underline{
	text-decoration-line: underline;
}

u{
	text-decoration: none;
	border-bottom: 2px solid black;
}

#batch-table {
	background-color: #fff;
}


.nav li a{
	color: #6c757d;
	opacity: 0.5;
}



.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}

/*updated here*/


.bgc{
	background: #F8F8F8 0% 0% no-repeat padding-box;
    opacity: 1;
 }

 .tab td {
         border-left: 1px solid lightgray;
         border-right: 1px solid lightgray;
}

table {
	text-align: center;
}

.badge {
    margin-right: 10px;
}

#refresh {
	margin-right: 20px;
}

#table-first-row  {
	height: 6px;
	border: 1px 0px 1px 0px;
}

#table-first-row td {
	background-color: #f8f8f8 !important;
	border:1px 0px 1px 0px;

}

#tab td {
	background-color: #ffffff;
}

input[type="number"]::-webkit-outer-spin-button, 
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
.batch-url a{
	color: #212529 !important;
}
.attribte-save{
	font-size: 14px !important;
}

.carousel-child-img {
	/*width: 51px;*/
	width: 24%;
	/* height: 39px; */
	height: auto;
	object-fit: cover;
	margin-left: 10px;
	margin-right: 10px;
}

.carousel-main-design-image-group {

}

#main-design-image-div { 
	/* height: 174px; */
	height: auto;
}

#product-info-card {
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
	padding: 5px !important;
}

#main-design-image-btn:focus {
	outline: none;
	box-shadow: none;
}

.main-design-image {
	max-width: 100%;
	/* padding: 0 20px 9px 20px; */
	height: auto;
	object-fit: cover;
}

#design-preview-model-dialog {
	max-width: 100%;
	margin: 25px;
}


.dashed-row-bottom {
	border-bottom: 1px dashed #dee2e6;
}

#design-preview-text {
	text-align: left;
	font: normal normal normal 14px/17px Lato;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;	
}

#design-product-name-text {
	text-align: left;
	font: normal normal normal 14px/17px Lato;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
}


.similar-design-text {
	text-align: left;
	font: normal normal normal 14px/17px Lato;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
}


#left-design-preview {
	padding-right: 3%;
	border-right: 1px dashed #dee2e6;
}


.child-btn {
	padding-left: 0px;
}


.design-preview-img {
	max-width: 400px;
    width: auto;
	height: 100%;
}

.child-img {
	max-width: 51px;
    width: auto;
	height: 39px;
}

.align-center {
	align-items: center;
}

#right-design-preview {
	padding-left: 3%;
}

.carousel-controls-icon-style {
	color: black;
	opacity: 1;
	visibility: visible;
}

#tab-content-attribute,#tab-content-edit-history{
	position: relative;
}
.varinat-image-event{
	height: 240px;
}
.variant-card{
	padding:15px;
}
.variant-main-content{
	position: relative;
}
.save-all-attr{
	float: right;
}

.save-all-attr::hover{
	color: white;
}

#approve-as-variant-model-content {
    max-width: 100%;
    min-height: 580px;
}
#approve-as-variant-model-dialog {
    max-width: 750px;
}
.new-cell-image {
	padding: 15px;
	display: inline-block;
	margin: 3px;
	background: #e1e4e9 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
	position: relative;
}

.variant-img-overlay {
	padding-left: 0px !important;
	padding-top: 0px !important;
}

.new-design-image {
	object-fit: cover;
	width: 138px !important;
	height: 138px;
	background: transparent 0% 0% no-repeat padding-box;
	opacity: 1;
}

.status-change-checkbox {
	width: 20px;
	height: 20px;
	background: #FFFFFF 0% 0% no-repeat padding-box;
	border: 1px solid #707070;
	border-radius: 5px;
	opacity: 1;
}

#modal-design-id-text {
	text-align: left;
	letter-spacing: 0px;
	opacity: 1;
}

#modal-variant-name-text {
	text-align: left;
	letter-spacing: 0px;
	opacity: 1;
}

.color-red {
	color: red;
}

#cancel-modal {
	background-color: #e9eef1;
}

#modal-reject-design-comment {
	height: 200px;
	resize: none;
}

#reject-design-model-dialog {
	max-width: 750px;
}

#reject-design-model-content {
	max-width: 100%;
	min-height: 580px;
}

#reject-design-confirm-text {
	text-align: left;
	font: normal normal normal 14px/17px Lato;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
}

#reject-design-text {
	text-align: left;
	letter-spacing: 0px;
	opacity: 1;
}

.carousel-controls-icon-style {
	color: black;
	opacity: 1;
	visibility: visible;
} 

.disabled-btn{
	background-color: #ebebeb !important;
	border-color: #ebebeb !important;
}

.batches-tab-similar-design-menu-group{
	padding-left: 15px;
	padding-right: 15px;
}

.batches-tab-span-text {
	text-align: left;
	font: normal normal normal 18px/22px Lato, sans-serif;
	letter-spacing: 0px;
	color: #182027;
	opacity: 1;
	
}

.nav-tabs .nav-link.active .batches-tab-similar-design-menu-group span {
    color: #182027 !important;
    opacity: 1 !important;
}

#batches_attribute_saveall_btn:hover{
	color: white !important;
}
.wrng-msg{
	padding-top: 5px;
	float: right;
	font-size: 14px;
}


.batch-design-preview-img-parent{
    height: 400px;
}

.preview-modal-main-design-carousel-active{
    border: 1px solid blue
}

.preview-modal-similar-design-carousel-active{
    border: 1px solid blue
}


.remove-pointer-event{
    pointer-events: none; 
}

.tooltip-disabled-position{
    position: relative;
    display: inline-block;
}