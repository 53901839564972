.navigation-text-font {
  font: normal normal 500 14px/17px lato, sans-serif;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;
}

#product-info-card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  padding: 20px 20px 20px 20px;
}

#product-name {
  text-align: left;
  font: normal normal 500 20px/24px Lato, sans-serif;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}

#vendor-design-number {
  text-align: left;
  font: normal normal 500 16px/19px Lato;
  letter-spacing: 0px;
  color: #8E9294;
  opacity: 1;
}

#variants {
  text-align: left;
  font: normal normal 500 16px/19px Lato, sans-serif;
  letter-spacing: 0px;
  color: #0D131A;
  opacity: 1;
}

.design-info {
  padding: 5px 0px;
}

.img-parent {
  width: 100%;
  height: 240px;
  padding: 20px;
}

.image-inside-div{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.card {
    height: 100%;
    
 }

.select-all-status-change-checkbox{
  width: 25px;
  height: 25px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
}

.status-change-checkbox {
  width: 25px;
  height: 25px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 5px;
  opacity: 1;
  position: absolute; 
    top:12px;
    left:12px;
 }

/*overriding nav-bar styles*/
#repo-title {
  color: #6D767F;
    opacity: 1;
    /*background-color: green !important;*/
}

.table-row-selected {
  background-color: #BCB9B9;
}

.repo-model-center{
  margin: auto;
  width: 50%;
}

.repo-preview-image-inside-div{
    display: block;
     max-width:100%;
     max-height:100%;
     width: auto;
     height: auto;
  /*width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;*/ 
}

/*.repo-preview-img-parent{
  width: 100%;
  height: 550px;
}*/

.repo-preview-img-parent{
  width: 100%;
  max-height: calc(100vh - 100px);

}

#repo-image-preview-model-dialog {
  max-width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top:25px;
  margin-bottom: 25px; 
  max-height: calc(100vh - 500px);
}

#repo-search-input-group {
  border: 1px solid #CCD1E1;
  border-left-width: 0px;
  opacity: 1;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 0px 5px 5px 0px;
}

#repo-filter-dropdown {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #CCD1E1;
  border-radius: 5px 0px 0px 5px;
  padding:0px 4px 0px 4px; 
  align-items: center;
  margin-right: 0px;
  opacity: 1;
}

#repo-filter-dropdown-btn:focus {
  outline: none;
}

.repo-filter-font {
  background: #FFFFFF ;
  border: none;
}

#repo-search-input:focus,
.repo-filter-font:focus,
.repo-search-icon:focus {
    outline: 0 !important;
}

.repo-search-icon {
  background-color: white; 
  border: none;
}

#repo-search-input {
  border: none;
  background-color: inherit;
  
}

.tooltip_gd_number {
  position: relative;
  display: inline-block;
}

.tooltip_gd_number .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px; 
}

.tooltip_gd_number:hover .tooltiptext {
  visibility: visible;
}

.tooltip_gd_number .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.repo-edithistory-table-alt-color{
  background: #F8F8F8 0% 0% no-repeat padding-box;
    opacity: 1;
}

.repo-batch-table-alt-color{
  background: #F8F8F8 0% 0% no-repeat padding-box;
    opacity: 1;
}

.repo-attribute-table-alt-color{
  background: #F8F8F8 0% 0% no-repeat padding-box !important;
    opacity: 1;
}

#request-images-model-dialog {
  max-width: 750px;
}

#request-images-model-content {
  max-width: 100%;
  min-height: 480px;
}

.dashed-row-bottom {
  border-bottom: 1px dashed #dee2e6;
}

#request-images-confirm-text {
  text-align: left;
  font: normal normal normal 14px/17px Lato, sans-serif;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;
}

#request-images-text {
  text-align: left;
  font: normal normal normal 14px/17px Lato, sans-serif;
  letter-spacing: 0px;
  color: #0D131A;
  opacity: 1;
}

#modal-request-images-comment {
  height: 156px;
  resize: none;
}

#request-img-btn:hover{
  color: #fff !important;
  text-decoration: none;
}

.dashed-row-top {
  border-top: 1px dashed #dee2e6;
}

#table_images_count_by_variant_name {
    table-layout: fixed;
    width: 100%;   
}

#table_images_count_by_variant_name th, 
#table_images_count_by_variant_name td {
    word-wrap: break-word;
}

.comment-img-parent{
  width: 100px;
  height: 100px;
}

.comment-image-inside-div{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

#comment-input {
  border: 0px;
  background-color: #dfe7f6;
}

#comment-plane-icon-div{
  align-items: center;
  background-color: #dfe7f6;
}

.comment-paper-plane{
  border-radius: 60%;
  width: 30px;
  height: 30px;
  background-color: #eff3fb;
}

input[type="text"]:focus{
  box-shadow: none;
}

.comment-cell-image {
  padding: 8px 8px 4px 8px;
  display: table-cell;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

#comment-message-group{
  border: 0px;
  background-color: white;
}

.comment-variant-name-span {
  text-align: left;
  font: normal normal normal 10px/8px Lato, sans-serif;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;
}

#comment-product-manager{
  text-align: left;
  font: normal normal normal 16px/19px Lato;
  letter-spacing: 0px;
  color: #182027;
  opacity: 0.5;
}

#comment-hd-image-request-comment {
  text-align: left;
  font: normal normal normal 14px/17px Lato;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;
}

.status-change-delete{
  position: absolute; 
  top: 2px;
  right: 2px;
}

.uploaded-img-parent{
  width: 100%;
  height: 250px;
  padding: 20px 20px 10px 20px;
}

.uploaded-image-inside-div{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

.uploaded-image-description-div{
  padding:0px 20px 0px 20px;
}

#table_images_count_by_variant_name tr{
  cursor: pointer;
}

.nav-tabs{
  border:0px !important;
}

.nav-tabs .nav-link.active {
    color: black !important;
    background-color: transparent !important;
    border: 0px !important;
    border-bottom: 3px solid gray !important;
    opacity: 1 !important;
}

#repo-design-name-product-info-card{
  text-align: left;
  font: normal normal 500 20px/24px Lato, sans-serif;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}

.repo-comment-date{
  font: normal normal 500 10px/24px Lato, sans-serif;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}

.repo-status-button-disabled {
  border: 1px solid #707070 !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  cursor: not-allowed;
}




.tooltip_request_image {
  position: relative;
  display: inline-block;
}

.tooltip_request_image .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 0;
  right: 105%;
}

.tooltip_request_image:hover .tooltiptext {
  visibility: visible;
}

.tooltip_request_image .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -8px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}

.repo-upload-button-disabled{
  border: 1px solid #707070 !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  cursor: not-allowed;
}

.repo-studio-variant-name {
  text-align: left;
  font: normal normal normal 12px/15px Lato, sans-serif;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;
}

.repo-studio-manager-img-parent{
  width: 100%;
  height: 240px;
}

.repo-studio-manager-img-inside-div{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}

#repo-tab-existing-images{
  text-align: left;
  font: normal normal normal 18px/22px Lato, sans-serif;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;
}

#repo-tab-new-images{
  text-align: left;
  font: normal normal normal 18px/22px Lato, sans-serif;
  letter-spacing: 0px;
  color: #6D767F;
  opacity: 1;
}

#repo-tab-attribute{
  text-align: left;
  font: normal normal normal 18px/22px Lato, sans-serif;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;

}

#repo-tab-edit-history{
  text-align: left;
  font: normal normal normal 18px/22px Lato, sans-serif;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;
}

#repo-tab-batch-details{
  text-align: left;
  font: normal normal 500 18px/22px Lato, sans-serif;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;
}

#repo-tab-comment-details{
  text-align: left;
  font: normal normal normal 18px/22px Lato, sans-serif;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;
}

.repo-tab-similar-design-menu-group {
  padding-left: 12px;
  padding-right: 12px;
}

.nav-tabs .nav-link.active .repo-tab-similar-design-menu-group span {
    color: #182027 !important;
    opacity: 1 !important;
}

#repo-attributes-table td{
    text-align: left; 
    vertical-align: middle;
}

#repo-edit-history-table td{
    vertical-align: middle;
    text-align: left;
}

.repo-existing-images-inline-loader{
    display: flex;
    justify-content: center;
    position: absolute;
    padding: 2rem 0;
    overflow: hidden;
    z-index: 4;
    width: 100%;
    height: 100%;
    background: white;
    margin: 0;
}

#new-images-count {
  text-align: left;
  font: normal normal normal 18px/22px Lato, sans-serif;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;
}

/*Active and InActive slider*/
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3 ;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.repo-inline-loader{
  display: flex;
  justify-content: center;
  position: absolute;
  padding: 2rem 0;
  overflow: hidden;
  z-index: 4;
  width: 100%;
  height: 100%;
  background: white;
  margin: 0;
}


.nohover-btn:hover {
    background-color: #343a40 !important;
    color: white !important;
}

#uploaded-images {
  text-align: left;
  font: normal normal normal 18px/22px Lato, sans-serif;
  letter-spacing: 0px;
  color: #182027;
  opacity: 1;
}

.batch-background {
    background-color:#FFFFFF;
    padding:10px;
    padding-bottom: 25%;
}

.repo-comments-inline-loader {
    display: flex;
    justify-content: center;
    position: absolute;
    padding: 2rem 0;
    overflow: hidden;
    z-index: 4;
    width: 100%;
    height: 100%;
    background: white;
    margin: 0;
}